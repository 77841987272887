import React from 'react';
import { Helmet } from 'react-helmet';

import { graphql, useStaticQuery } from 'gatsby';
import {
  Box, Container, Grid, makeStyles, Typography, Theme, createStyles
} from '@material-ui/core';
import UFOShowcaseThumbnail from '../components/media/UFOShowcaseThumbnail';
import { IVimeoShowcase } from '../types/vimeo';
import { navigate } from 'gatsby';

const query = graphql`
  {
  allVimeoShowcase(sort: {order: ASC, fields: order}) {
    nodes {
      fields {
        slug
      }
      name
      numberOfVideos
      thumbnailImg {
        childImageSharp {
          fluid(maxWidth: 1216, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    showcase: { '&:hover': { cursor: 'hand' } },
    title: {
      maxWidth: '800px',
      margin: 'auto',
      paddingLeft: '40px',
      paddingRight: '40px',
      paddingBottom: '10px',
      marginBottom: '30px',
      textAlign: 'center',
      borderBottom: '1px solid #e5e5e5'
    }
  })
);

export default () => {
  const classes = useStyles();
  const result = useStaticQuery(query);

  return (
    <>
      <Helmet title="Home" />
      <Container>
        <Box className={classes.title} >
          <Typography component="div" variant="h5">{'View the how-to videos and learn the fundamentals of your car and the UFODRIVE app.'}</Typography>
        </Box>

        <Grid xs={12} container spacing={2} >
          {result.allVimeoShowcase.nodes.map((vimeoShowcase: IVimeoShowcase, index: number) => (
            <Grid
              sm={index === 0 ? 12 : 6} xs={12} item
              key={vimeoShowcase.name} className={classes.showcase}
            >
              <UFOShowcaseThumbnail
                vimeoShowcase={vimeoShowcase}
                //disabled={video.uri === playedVideo.uri}
                handleOpen={(_vimeoShowcase) => { navigate(`/${_vimeoShowcase.fields.slug}`); }}
              />
            </Grid>
          ))}
        </Grid>

      </Container>
    </>
  );
};
