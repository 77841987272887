import React from 'react';
import { colors, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import { IVimeoShowcase } from '../../types/vimeo';
import BackgroundImage from 'gatsby-background-image';

interface IProps {
  vimeoShowcase: IVimeoShowcase
  disabled?: boolean
  handleOpen?: (vimeoShowcase: IVimeoShowcase) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    root: { width: '100%' },
    bg: {
      backgroundPosition: 'center center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      width: '100%',
      cursor: 'auto',
      '&:hover': { cursor: 'pointer' }

    },
    container: {
      position: 'relative',
      width: '100%',
      height: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'

    },
    text: {
      paddingLeft: '5%',
      paddingBottom: '5%',
      color: colors.common.white

    }
  })
);

const UFOShowcaseThumbnail = ({ vimeoShowcase, disabled = false, handleOpen }: IProps) => {
  const classes = useStyles();

  return (
    <BackgroundImage
      Tag="section"
      className={classes.bg}
      fluid={vimeoShowcase.thumbnailImg.childImageSharp.fluid}
      onClick={() => { handleOpen && handleOpen(vimeoShowcase); }}
    >
      <div className={classes.container} >
        <div className={classes.text}>
          <Typography variant="h4">{vimeoShowcase.name}</Typography>
          <Typography variant="caption">{`${vimeoShowcase.numberOfVideos} video${vimeoShowcase.numberOfVideos > 1 ? 's' : ''}`}</Typography>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default UFOShowcaseThumbnail;
